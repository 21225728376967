import React from 'react';
import ItemGrid from '../components/ItemGrid';
import LoadingGrid from '../components/LoadingGrid';
import { HomePageGrid } from '../styles/Grids';
import useLatestData from '../utils/useLatestData';

const CurrentlySlicing = ({ slicemasters }) => (
  <div>
    <h2 className="center lefttilt">
      <span className="mark">Slicemasters On</span>
    </h2>
    <p>Ready to Slice for You</p>
    {!slicemasters && <LoadingGrid count={2} />}
    {slicemasters && !slicemasters?.length && (
      <p>No one is working right now!</p>
    )}
    {slicemasters?.length && (
      <ItemGrid items={slicemasters} baseUrl="/slicemaster" />
    )}
  </div>
);

const HotSlices = ({ hotSlices }) => (
  <div>
    <h2 className="center lefttilt">
      <span className="mark">Pizzas in the case</span>
    </h2>
    <p>Hot and Ready</p>
    {!hotSlices && <LoadingGrid count={2} />}
    {hotSlices && !hotSlices?.length && <p>Nothing in the case</p>}
    {hotSlices?.length && <ItemGrid items={hotSlices} baseUrl="/pizza" />}
  </div>
);

const HomePage = () => {
  const { hotSlices, slicemasters } = useLatestData();
  return (
    <div className="center">
      <h1>The Best Pizza in Southport, NC</h1>
      <p>Open 11am to 11pm Every Single Day</p>
      <HomePageGrid>
        <CurrentlySlicing slicemasters={slicemasters} />
        <HotSlices hotSlices={hotSlices} />
      </HomePageGrid>
    </div>
  );
};

export default HomePage;
