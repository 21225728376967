import { useEffect, useState } from 'react';

const gql = String.raw;

const deets = gql`
    name
    _id
    slug {
      current
    }
    image {
      asset {
        url
        metadata {
          lqip
        }
      }
    }
`;

const query = gql`
  query {
    StoreSettings(id: "store") {
      storeName
      slicemaster {
        ${deets}
      }
      hotSlices {
        ${deets}
      }
    }
  }
`;

const useLatestData = () => {
  const [hotSlices, setHotslices] = useState();
  const [slicemasters, setSlicemasters] = useState();
  // Use a side effect to fetch the data
  useEffect(() => {
    fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    })
      .then((res) => res.json())
      .then(
        ({
          data: {
            StoreSettings: { hotSlices: slices, slicemaster },
          },
        }) => {
          setHotslices(slices);
          setSlicemasters(slicemaster);
        }
      )
      .catch((error) => console.log('Fetch Error!! ', { error }));
  }, []);
  return {
    hotSlices,
    slicemasters,
  };
};
export default useLatestData;
